"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryGameMembershipRepository = void 0;
function InMemoryGameMembershipRepository() {
    const _repo = {};
    return {
        getPlayerIdFor(gameId) {
            return _repo[gameId] || null;
        },
        savePlayerIdFor(gameId, playerId) {
            _repo[gameId] = playerId;
        }
    };
}
exports.InMemoryGameMembershipRepository = InMemoryGameMembershipRepository;
