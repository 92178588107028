"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoFishGameplayClient = void 0;
const Nes = require("@hapi/nes/lib/client");
const game_membership_repository_1 = require("./game-membership-repository");
function GoFishGameplayClient(websocketUrl, gameMembershipRepository = game_membership_repository_1.InMemoryGameMembershipRepository()) {
    /* Connection management */
    const client = new Nes.Client(websocketUrl);
    let connected = false;
    let connectionPromise = null;
    client.onConnect = () => { connected = true; };
    client.onDisconnect = () => { connected = false; };
    client.onError = err => { console.error("NES CLIENT ERROR:", err); };
    /* Client state */
    const setPlayerIdCallbacks = [];
    const updateGameStateCallbacks = [];
    let playerId = null;
    let joinedGame = null;
    let latestGameState = null;
    function useExistingPlayer(gameId) {
        return __awaiter(this, void 0, void 0, function* () {
            return gameMembershipRepository.getPlayerIdFor(gameId);
        });
    }
    function createNewPlayer(gameId) {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield client.request({
                path: `/api/game/${gameId}/player`,
                method: "POST"
            })).payload.playerId;
        });
    }
    function restoreGameFromLocalState() {
        return client.request({
            path: `/api/game/${joinedGame}`,
            method: "POST",
            payload: {
                type: "RESTORE",
                gameState: latestGameState
            }
        });
    }
    function performGameAction(action, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const request = {
                path: `/api/game/${joinedGame}`,
                method: "POST",
                payload: Object.assign({ type: action }, options)
            };
            try {
                return yield client.request(request);
            }
            catch (e) {
                if (e.statusCode === 404) {
                    yield restoreGameFromLocalState();
                    return yield client.request(request)
                        .catch(e2 => console.error("Still received unsuccessful status from server after restoring the game:", e2));
                }
                else {
                    console.error("Received unsuccessful status from server:", e);
                    return null;
                }
            }
        });
    }
    function updateGameState(gameState) {
        latestGameState = gameState;
        updateGameStateCallbacks.forEach(callback => callback(gameState));
    }
    return {
        createGame(template) {
            return client.request({
                path: `/api/game`,
                method: "POST",
                payload: { template: template },
            }).then((response) => {
                return response.payload;
            });
        },
        joinGame(gameId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield client.subscribe(`/api/game/${gameId}`, payload => { updateGameState(payload.state); });
                joinedGame = gameId;
                playerId = (yield useExistingPlayer(gameId)) || (yield createNewPlayer(gameId));
                gameMembershipRepository.savePlayerIdFor(gameId, playerId);
                setPlayerIdCallbacks.forEach(callback => callback(playerId));
                const gameState = (yield client.request({
                    path: `/api/game/${gameId}`,
                    method: "GET",
                })).payload;
                updateGameState(gameState);
            });
        },
        renamePlayer(name) {
            performGameAction("RENAME", {
                player: playerId,
                name: name
            });
        },
        removePlayer(playerId) {
            performGameAction("REMOVE_PLAYER", {
                player: playerId
            });
        },
        draw() {
            return __awaiter(this, void 0, void 0, function* () {
                yield performGameAction("DRAW", {
                    player: playerId
                });
            });
        },
        give(cardIds, recipientId) {
            performGameAction("GIVE", {
                player: playerId,
                recipient: recipientId,
                cardIds
            });
        },
        score(cardIds) {
            performGameAction("SCORE", {
                player: playerId,
                cardIds
            });
        },
        hideOrShowCard(cardId) {
            performGameAction("SHOW_OR_HIDE_CARD", {
                player: playerId,
                card: cardId
            });
        },
        endTurn() {
            return __awaiter(this, void 0, void 0, function* () {
                performGameAction("END_TURN");
            });
        },
        onSetPlayerId(callback) {
            setPlayerIdCallbacks.push(callback);
        },
        onUpdateGameState(callback) {
            updateGameStateCallbacks.push(callback);
        },
        connect() {
            if (connectionPromise)
                return connectionPromise;
            if (connected)
                return Promise.resolve();
            return connectionPromise = client.connect().then(() => {
                connectionPromise = null;
            });
        },
        disconnect() {
            return client.disconnect();
        },
        isConnected: () => connected
    };
}
exports.GoFishGameplayClient = GoFishGameplayClient;
